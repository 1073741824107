"use client";
import { motion, useAnimation, useInView } from "framer-motion";
import { HTMLAttributes, useEffect, useRef } from "react";

interface FadeInProps extends HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
	duration: number;
	delay: number;
	className?: string;
}
function FadeIn({ children, duration, delay, className }: FadeInProps) {
	const controls = useAnimation();

	const ref = useRef(null);
	const inView = useInView(ref);

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return (
		<motion.div
			ref={ref}
			animate={controls}
			initial="hidden"
			transition={{ duration, delay }}
			variants={{
				visible: { opacity: 1 },
				hidden: { opacity: 0 },
			}}
			className={className}
		>
			{children}
		</motion.div>
	);
}

export default FadeIn;
