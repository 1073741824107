import { imageQuery } from "./image";


const getGridPageQuery = `* [ 
  _type == "projectImage" &&
  !(_id in path("drafts.**"))
  && showGrid == true
] {
  
  ...${imageQuery}
    
}|order(orderRank)`;

export default getGridPageQuery;
