"use client";
import FadeIn from "@/components/animations/FadeIn";
import LazyImage from "@/components/LazyImage";
import Loader from "@/components/Loader";
import {
	BlurList,
	BlurListItem,

} from "@/components/ui/blur-list";
import { cn } from "@/lib/utils";

import { sanity } from "@/sanity/lib/client";
import getGridPageQuery from "@/sanity/query/getGridPage";

import { ImageType } from "@/sanity/query/image";
import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import { notFound } from "next/navigation";


export default function GridPage() {
	const {
		data: images,
		isError,
		isPending,
	} = useQuery({
		queryKey: ["gridPage"],
		queryFn: async (): Promise<ImageType[]> => {
			return await sanity.fetch(getGridPageQuery);
		},
	});

	if (isPending) {
		return <Loader />;
	}

	if (isError || !images) {
		return notFound();
	}

	return (
		<BlurList
			className={cn(
				"scrollbar-hide",
				"overflow-x-hidden overflow-y-scroll px-[8px] py-[89px] nav:px-[13px] nav:py-[89px]",
				"grid grid-cols-3 gap-[8px] nav:gap-[13px]",
				"nav:grid-cols-5",
				"lg:grid-cols-8",
			)}
		>
			{images?.map((image, index) => {
				return (
					<BlurListItem
						key={index}
						index={index}
						className="group relative col-span-1"
					>
						<Link
							href={`/format/${image?.category?.slug}/${image?.project?.slug}`}
							scroll={false}
						>
							<FadeIn duration={1} delay={Math.random() * 0.7}>
								<LazyImage
									image={image}
									className={cn("aspect-[1.3] object-cover")}
								/>
							</FadeIn>
						</Link>
					</BlurListItem>
				);
			})}
		</BlurList>
	);
}
